.service{
    .service-link{
        font-size: 16px;
        text-decoration: underline;
        color: var(--color-blue);
        text-underline-offset: 0.1em;
        text-decoration-thickness: 1px;
        transition: 0.3s;
        font-weight: 500;
        svg{
            transition: 0.3s;
            margin-left: 2px;
        }
        &:hover{
            text-decoration: none;
            svg{
                transform: translateX(3px);
            }
        }
    }
}